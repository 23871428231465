import moment from 'moment';
import { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { CgClose, CgSearch } from 'react-icons/cg';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { EDIT_SOURCE_DUMP } from '../action/apiPath';
import Loader from '../components/Loader';
import PolygonInfo from '../PolygonInfo';
import Settings from './Settings';
import source from '../assets/images/source-tooltip-icon.svg';
import point from '../assets/images/point.svg';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { CallWithAuth } from '../action/apiActions';
export default function AllPolygons(props) {
  const {
    isLoading,
    closeEditModal,
    setPolygons,
    fetchPolygon,
    setIsLoading = () => {},
    type = 'source',
    data = [],
    onSelect,
    selectedPoly,
    setShowCreateSourcePointModal,
    triggerFetch,
    requiredMesage,
    setshowSourcePointsModal = () => {},
    setSelectedDumpPoint = () => {},
    setPopupMessage,
    setRequiredMessage = () => {},
    setPopupConfirmation = () => {},
    tableState,
    setTableState,
    searchQuery,
    setSearchQuery = () => {},
    materials = [],
    clearPolygons = () => {},
    pointSelected = {
      coordinate: {
        lat: 0.0,
        lng: 0.0,
      },
      index: 0,
    },
    setSelectPoint = () => {},
    onShowEditModal = () => {},
  } = props;

  const [hover, setHover] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [updateDumpPoint, setUpdateDumpPoint] = useState({});
  const [showSettings, setShowSettings] = useState(-1);

  useEffect(() => {
    console.log('@@@@@@@@@@@@@@@@@@@@@');
    setShowEditModal(false);
  }, [closeEditModal]);

  const submitHandler = async (values, status = 'Active') => {
    if (
      !values?.name ||
      values?.materials?.length === 0 ||
      !values?.materials
    ) {
      // props.setPopupMessage('Fill in all fields of the form.');
      setRequiredMessage('*Please, fill up all the obligatory fields');
      return;
    }
    try {
      values.status = status;
      if (status === 'Inactive') {
        values.disabled_on = new Date().toISOString();
      }
      try {
        const response = await CallWithAuth('POST', EDIT_SOURCE_DUMP, values);
        if (response.res.status === 201) {
          triggerFetch();
          setPopupMessage('Changes Saved Successfully!');
          setShowEditModal(false);
          onShowEditModal(false);
          setshowSourcePointsModal(false);
          // setDisable(false)
        }
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error, 'erro Line 69');
      toast.error('Server Error', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
      });
    }
  };

  function getMaterialColor(materialType) {
    console.log('_________________________', materialType);
    const material = materials.find(
      (item) => item.MaterialType === materialType,
    );

    return material ? material.color : '#000000'; // Replace "#000000" with your desired default color
  }

  const handleOutsideClick = (event) => {
    const myDiv = document.getElementById('settings');
    if (
      !myDiv?.contains(event.target) &&
      event.target.nodeName !== 'svg' &&
      event.target.nodeName !== 'path'
    ) {
      // Perform your desired functionality here
      setShowSettings(-1);
    }
  };

  // Add the event listener to the document to capture clicks anywhere on the page
  document.addEventListener('click', handleOutsideClick);

  const customSort = (a, b) => {
    if (a.name === 'Unknown') return -1;
    if (b.name === 'Unknown') return 1;
    return 0;
  };

  const InActiveTable = ({ onSelectRow }) => {
    const list = data?.filter((e) => e?.status === 'Inactive');
    list?.sort(customSort);

    return (
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <div
            style={{
              height: 'calc(100% - 50px)',
              borderRadius: 5,
              width: '100%',
            }}
          >
            <tr
              className="d-flex"
              style={{
                font: 'normal normal 600 13px/17px Open Sans',
                padding:
                  type === 'source'
                    ? ' 1.15rem '
                    : ' 2rem 1.15rem  1.5rem 1.15rem',
                borderTop: '1px solid #EBEBEB',
                borderBottom: '1px solid #707070',
              }}
            >
              <td
                style={{
                  width: '1%',
                }}
              />
              <td className="mm-global-font-1" style={{ width: '36%' }}>
                {type === 'source' ? 'Source Point' : 'Dump Point'}
              </td>
              <td className="mm-global-font-1" style={{ width: '36%' }}>
                Last Activity
              </td>
              <td className="mm-global-font-1" style={{ width: '22%' }}>
                Disabled On
              </td>
            </tr>
            <div
              style={{
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100% - 40px)',
                padding: '0 0px 0',
              }}
            >
              <div
                style={{
                  paddingBottom: '50px',
                  height: 'max-content',
                }}
                className="d-flex flex-column"
              >
                {list?.map((source, index) => {
                  return (
                    <div className="d-flex">
                      <tr
                        onClick={() => {
                          onSelectRow(source);
                          setShowSettings(-1);
                        }}
                        className="d-flex  align-items-stretch"
                        key={index}
                        style={{
                          background:
                            selectedPoly?._id === source._id &&
                            (selectedPoly?._id == undefined
                              ? selectedPoly?.name == source?.name
                              : true)
                              ? '#05795F1A 0% 0% no-repeat padding-box'
                              : '#FFFFFF66 0% 0% no-repeat padding-box',

                          borderTop:
                            selectedPoly?._id === source._id &&
                            (selectedPoly?._id == undefined
                              ? selectedPoly?.name == source?.name
                              : true)
                              ? '1px solid #198f23'
                              : '0px solid #EBEBEB',

                          borderBottom:
                            selectedPoly?._id === source._id &&
                            (selectedPoly?._id == undefined
                              ? selectedPoly?.name == source?.name
                              : true)
                              ? '1px solid #198f23'
                              : '1px solid #EBEBEB',
                          fontSize: '13px',
                          width: '100%',
                          padding: '20px 15px',
                        }}
                      >
                        <td
                          style={{
                            width: '2%',
                            borderTopLeftRadius: 5,
                            borderBottomLeftRadius: 5,
                          }}
                        />

                        <td
                          className="py-2 mm-global-font-2"
                          style={{ width: '36%', lineHeight: '10%' }}
                        >
                          {source?.name ?? '-'}
                        </td>
                        <td
                          className="py-2 mm-global-font-2"
                          style={{ width: '36%', lineHeight: '10%' }}
                        >
                          {moment().format('DD/MM/YYYY')}
                        </td>
                        <td
                          className="py-2 mm-global-font-2"
                          style={{ width: '22%', lineHeight: '10%' }}
                        >
                          {moment(source?.disabled_on).format('DD/MM/YYYY')}
                        </td>
                      </tr>

                      <div
                        className=" pt-2"
                        style={{
                          background:
                            selectedPoly?._id === source._id &&
                            (selectedPoly?._id == undefined
                              ? selectedPoly?.name == source?.name
                              : true)
                              ? '#05795F1A 0% 0% no-repeat padding-box'
                              : '#FFFFFF66 0% 0% no-repeat padding-box',

                          borderTop:
                            selectedPoly?._id === source._id &&
                            (selectedPoly?._id == undefined
                              ? selectedPoly?.name == source?.name
                              : true)
                              ? '1px solid #198f23'
                              : '0px solid #EBEBEB',

                          borderBottom:
                            selectedPoly?._id === source._id &&
                            (selectedPoly?._id == undefined
                              ? selectedPoly?.name == source?.name
                              : true)
                              ? '1px solid #198f23'
                              : '1px solid #EBEBEB',
                        }}
                      >
                        <div id="settings">
                          <div className="mm-ellipse_dropdown ">
                            <span
                              onClick={() => setShowSettings(index)}
                              className="mm-ellipse_dropdown_button "
                            >
                              <BsThreeDotsVertical
                                onClick={() => setShowSettings(index)}
                              />
                            </span>
                            {showSettings == index && (
                              <Settings
                                setIsLoading={setIsLoading}
                                fetchPolygon={fetchPolygon}
                                dumpPoint={source}
                                setShowEditModal={(v) => {
                                  clearPolygons();
                                  setShowEditModal(v);
                                  onShowEditModal(v);
                                }}
                                triggerFetch={triggerFetch}
                                setUpdateDumpPoint={setUpdateDumpPoint}
                                setPopupConfirmation={setPopupConfirmation}
                                setshowSourcePointsModal={
                                  setshowSourcePointsModal
                                }
                                tableState={tableState}
                                submitHandler={submitHandler}
                                onSelect={onSelect}
                                setSelectedDumpPoint={setSelectedDumpPoint}
                                type={type}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  // Sort the data array using the customSort function

  const ActiveTable = ({ data, onSelectRow, materials }) => {
    if (type === 'dump') {
      let newKey = 'status';
      let defaultValue = 'Active';
      if (data?.length > 0) {
        for (let i = 0; i < data?.length; i++) {
          const item = data[i];
          if (!item?.hasOwnProperty(newKey)) {
            item[newKey] = defaultValue;
          }
        }
      }
    }

    let list = data?.filter((e) => e?.status === 'Active');

    list?.sort((a, b) => a?.name?.localeCompare(b?.name));
    list?.sort(customSort);
    return (
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <div
            style={{
              height: 'calc(100% - 50px)',
              borderRadius: 5,
              width: '100%',
            }}
          >
            <tr
              className="d-flex "
              style={{
                font: 'normal normal 600 13px/17px Open Sans',
                padding:
                  type === 'source'
                    ? ' 1.15rem '
                    : ' 2rem 1.15rem  1.5rem 1.15rem',
                borderBottom: '1px solid #707070',
                borderTop: '1px solid #EBEBEB',
              }}
            >
              <td
                style={{
                  width: '1%',
                }}
              />
              <td className="mm-global-font-1" style={{ width: '30%' }}>
                {type === 'source' ? 'Source Point' : 'Dump Point'}
              </td>
              <td className="mm-global-font-1" style={{ width: '23.3%' }}>
                {type === 'source' ? 'Excavators' : 'Trucks'}
              </td>
              <td className="mm-global-font-1" style={{ width: '23.3%' }}>
                Materials
              </td>
              <td className="mm-global-font-1" style={{ width: '23.3%' }}>
                Total Loads
              </td>
            </tr>
            <div
              style={{
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100% - 40px)',
              }}
            >
              <div
                style={{
                  // paddingBottom: "50px",
                  height: 'max-content',
                }}
                className="d-flex flex-column"
              >
                {list?.map((source, index) => {
                  return (
                    <>
                      {(
                        source?.name == 'Unknown'
                          ? type === 'source'
                            ? source?.excavators?.count !== 0
                            : source?.totalLoads !== 0
                          : true
                      ) ? (
                        <div
                          style={{
                            background:
                              selectedPoly?._id === source._id &&
                              (selectedPoly?._id == undefined
                                ? selectedPoly?.name == source?.name
                                : true)
                                ? '#05795F1A 0% 0% no-repeat padding-box'
                                : '#FFFFFF66 0% 0% no-repeat padding-box',

                            borderTop:
                              selectedPoly?._id === source._id &&
                              (selectedPoly?._id == undefined
                                ? selectedPoly?.name == source?.name
                                : true)
                                ? '1px solid #198f23'
                                : '0px solid #EBEBEB',

                            borderBottom:
                              selectedPoly?._id === source._id &&
                              (selectedPoly?._id == undefined
                                ? selectedPoly?.name == source?.name
                                : true)
                                ? '1px solid #198f23'
                                : '1px solid #EBEBEB',
                          }}
                          className="d-flex align-items-center"
                        >
                          <tr
                            onClick={() => {
                              onSelectRow(source);
                              setShowSettings(-1);
                            }}
                            className="d-flex align-items-center "
                            key={index}
                            style={{
                              fontSize: '13px',
                              width: '100%',
                              padding: '9px 15px',
                            }}
                          >
                            <td
                              style={{
                                width: '1%',
                                borderTopLeftRadius: 5,
                                borderBottomLeftRadius: 5,
                              }}
                            />

                            <td
                              className="py-2 mm-global-font-2"
                              style={{
                                width: '32%',
                                lineHeight: '10%',
                                font:
                                  source?.name === 'Unknown'
                                    ? 'italic normal normal 12px/17px Open Sans'
                                    : '',

                                fontSize: '1rem',
                              }}
                            >
                              {source?.name ?? '-'}
                            </td>
                            <td
                              className="py-2 mm-global-font-2"
                              style={{ width: '23%', lineHeight: '10%' }}
                            >
                              {type === 'source'
                                ? source?.excavators?.count === 0
                                  ? '-'
                                  : source?.excavators?.count
                                : source?.totalLoads ?? '-'}
                            </td>
                            <td
                              className="py-2 mm-global-font-2"
                              style={{
                                width: '25%',
                                lineHeight: '10%',
                                cursor: 'pointer',
                              }}
                            >
                              {source?.materials &&
                              (type === 'source'
                                ? source?.excavators?.loads !== 0
                                : source?.totalLoads !== 0) ? (
                                source?.materials?.length > 1 ? (
                                  <a
                                    href="#"
                                    data-tooltip-id="mm-my-tooltip-data-html"
                                    data-tooltip-html={`<ul style="width:max-content">
                    ${source?.materials
                      ?.map(
                        (e, i) => `<li  style="font-size:1.1rem"  key=${i}>
                    <span  class=${
                      e?.value === 'Coal' ? 'mm-dot-1' : 'mm-dot-2'
                    }  style="background-color:${getMaterialColor(
                      e?.value,
                    )}"  ></span>
                  ${e?.value}</li>`,
                      )
                      .join('')}
              </ul>`}
                                  >
                                    {source?.materials?.length ?? '-'}
                                  </a>
                                ) : (
                                  source?.materials?.map((e) => e?.value)
                                )
                              ) : (
                                '-'
                              )}
                            </td>
                            <td
                              className="py-2 mm-global-font-2"
                              style={{ lineHeight: '10%' }}
                            >
                              {type === 'source'
                                ? source?.excavators?.loads === 0
                                  ? '-'
                                  : source?.excavators?.loads
                                : source?.totalLoads ?? ''}
                            </td>
                          </tr>

                          <div className=" " style={{}}>
                            <div id="settings">
                              <div className="mm-ellipse_dropdown ">
                                <span
                                  onClick={() => setShowSettings(index)}
                                  className="mm-ellipse_dropdown_button "
                                >
                                  {source?.name !== 'Unknown' ? (
                                    <BsThreeDotsVertical
                                      onClick={() => setShowSettings(index)}
                                    />
                                  ) : (
                                    <div style={{ width: '1.2rem' }}></div>
                                  )}
                                </span>
                                {showSettings == index && (
                                  <Settings
                                    fetchPolygon={fetchPolygon}
                                    dumpPoint={source}
                                    setShowEditModal={(v) => {
                                      clearPolygons();
                                      setShowEditModal(v);
                                      onShowEditModal(v);
                                    }}
                                    triggerFetch={triggerFetch}
                                    setUpdateDumpPoint={setUpdateDumpPoint}
                                    setPopupConfirmation={setPopupConfirmation}
                                    setshowSourcePointsModal={
                                      setshowSourcePointsModal
                                    }
                                    tableState={tableState}
                                    submitHandler={submitHandler}
                                    onSelect={(v) => {
                                      onSelect(v);
                                      v.popup == false && clearPolygons();
                                    }}
                                    setSelectedDumpPoint={setSelectedDumpPoint}
                                    type={type}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  const cancelHandler = () => {
    setPopupConfirmation({
      title: 'Fill out the form or your changes won’t be saved.',
      description: 'Are you sure you want to cancel?',
      cancelText: 'Discard',
      confirmText: 'Fill Form',
      onCancel: () => {
        props.setPopupConfirmation('');
        setShowEditModal(false);
        onShowEditModal(false);
      },
      onConfirm: () => {
        props.setPopupConfirmation('');
      },
    });
  };

  function handleSearch(e) {
    setSearchQuery(e.target.value);

    console.log(data, 'dskdjs');
    if (e.target.value === '') {
      fetchPolygon();
      return;
    }
    const filterBySearch = data?.filter((item) => {
      if (item?.name?.toLowerCase().includes(e.target.value?.toLowerCase())) {
        return item;
      }
    });
    setPolygons(filterBySearch);
  }

  if (showEditModal) {
    return (
      <PolygonInfo
        edit={true}
        showdummy={false}
        polygon={selectedPoly}
        onCancel={cancelHandler}
        onSubmit={submitHandler}
        close={() => {
          setShowEditModal(false);
          onShowEditModal(false);
        }}
        setRequiredMessage={setRequiredMessage}
        requiredMesage={requiredMesage}
        onChange={(v) => onSelect({ ...v, popup: false })}
        pointSelected={pointSelected}
        setSelectPoint={setSelectPoint}
      />
    );
  }

  return (
    <div
      style={{
        top: '4.7rem',
        left: '0rem',
        position: 'absolute',
        width: '600px',
        height: 'calc(100% - 9rem)',
        background: ' #FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: ' 0px 0.25rem 0.5rem #00000029',
        borderRadius: '0.85rem',
        zIndex: 2,
      }}
      className="mx-3 px-1"
    >
      <div
        className=""
        style={{
          padding: '1.25rem 28px',
          borderRadius: '0.85rem 0.85rem 0px 0px',
          backgroundColor: '',
        }}
      >
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ marginBottom: '2rem' }}
        >
          <div className="d-flex align-items-center">
            <Image
              src={type === 'source' ? source : point}
              style={{
                height: type === 'source' ? '1.7rem' : '1.33rem',
                width: type === 'source' ? '2.05rem' : '0.92rem',
              }}
            />
            <span
              className=" mm-source-modal-heading"
              // style={{
              //   font: 'normal normal 600 18px/24px Open Sans',
              //   marginLeft: '5px',
              // }}
            >
              {type === 'source' ? 'SOURCE POINTS' : 'DUMP POINTS'}
            </span>
          </div>
          <CgClose
            onClick={props?.close}
            style={{ cursor: 'pointer' }}
            size={27}
            className="px-1"
          />
        </div>
        <div
          style={{
            width: '100%',
          }}
        >
          <div
            className="px-2 my-2 d-flex align-items-center "
            style={{
              height: '3rem',
              background: '#FFFFFF 0% 0% no-repeat padding-box',
              border: '1px solid #EBEBEB',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '40px',
              width: '100%',
              padding: 8,
            }}
          >
            <CgSearch
              color="gray"
              size={25}
              style={{
                margin: '2px  7px 0px 7px',
              }}
            />
            <input
              type="text"
              placeholder={
                type === 'source'
                  ? 'Search for a source point'
                  : 'Search for a dump point'
              }
              className="shadow-none"
              style={{
                paddingBottom: '0px',
                paddingTop: '0px',
                borderTop: '1px solid #EBEBEB',
                borderBottom: '1px solid #EBEBEB',
                border: '0px',
                outline: '0px',
                width: '100%',
                font: 'normal normal normal 1.15rem/1.5rem Open Sans',
                fontSize: '1.2rem',
                height: '2rem',
              }}
              value={searchQuery}
              onChange={(e) => handleSearch(e)}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          padding: '15px 28px',
          height: 'calc(100% - 130px)',
        }}
      >
        <div className="d-flex">
          <p
            className=" mm-global-font-2"
            onClick={() => setTableState(true)}
            style={{
              marginRight: '0.85rem',
              font: tableState
                ? 'normal normal bold Open Sans'
                : 'normal normal  Open Sans',
              fontWeight: tableState ? 600 : 400,
              color: tableState ? '#000' : '#707070',
              cursor: 'pointer',
              marginBottom: '-1.2px',
              borderBottom: tableState ? '1.5px solid #05795F' : '',
              paddingBottom: '5px',
              fontSize: '1rem',
            }}
          >
            Active
          </p>
          <p
            className=" mx-4 mm-global-font-2"
            onClick={() => setTableState(false)}
            style={{
              marginBottom: '-1.2px',
              cursor: 'pointer',
              color: tableState === false ? '#000' : '#707070',
              fontWeight: tableState ? 400 : 600,

              font:
                tableState === false
                  ? 'normal normal bold  Open Sans'
                  : 'normal normal  Open Sans',
              borderBottom: tableState === false ? '1.5px solid #05795F' : '',
              paddingBottom: '5px',
              fontSize: '1rem',
            }}
          >
            {' '}
            Inactive
          </p>
        </div>
        {tableState ? (
          <ActiveTable
            data={data}
            onSelectRow={onSelect}
            materials={materials}
          />
        ) : (
          <InActiveTable onSelectRow={onSelect} />
        )}
        {
          <p
            onClick={() => onSelect({})}
            className=" d-flex justify-content-end "
            style={{
              color: '#387FF3',
              cursor: 'pointer',
              fontSize: '1rem',
              position: 'absolute',
              bottom: 'calc(1vh)',
              right: '1vh',
            }}
          >
            {(
              selectedPoly?._id == undefined
                ? selectedPoly?.name == 'Unknown'
                : selectedPoly?._id
            )
              ? 'Clear Selection'
              : ''}{' '}
          </p>
        }
      </div>
      <Tooltip
        id="mm-my-tooltip-data-html"
        place={'bottom'}
        style={{
          width: '138px',
          boxShadow: '1px 6px 0.85rem #00000029',
          backgroundColor: '#FFFFFF',
          color: 'black',
          border: '1px solid #EBEBEB',
          borderRadius: '4px',
        }}
      />
    </div>
  );
}
