import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import 'react-calendar/dist/Calendar.css';
import { GET_HISTORY } from '../action/apiPath';
import { ShiftHistoryContext } from '../context/history';
import DownloadModal from '../components/DownloadModal/index';
import Loader from '../components/Loader';
import ModalView from '../components/ModalView/index';
import PdfModal from '../components/PdfModal/index';
import SubHeader from '../components/SubHeader/index';
import JobDailyReport from '../components/reports/job-daily-report';
import { formatDate } from './helper';
import './history-style.css';
import { CallWithAuth } from '../action/apiActions';

export const headerText = [
  'Truck',
  'Load Time',
  'Material',
  'Source Point',
  'From Excavator',
  'Exc Operator',
  'Dump at point',
  'By Truck Operator',
  'Dump Time',
];

const getSingleCSVData = (unit) =>
  unit.operatorLoads.map((operatorLoad) => ({
    name: unit.name,
    firstLoad: formatDate(operatorLoad.firstLoad),
    lastLoad: formatDate(operatorLoad.lastLoad, '00:00'),
    operatorName: operatorLoad.operatorName,
    loginTime: formatDate(operatorLoad.loginTime),
    logoutTime: formatDate(operatorLoad.logoutTime, '00:00'),
  }));

const getCSVData = (shift) =>
  shift.flatMap((unit) =>
    unit.operatorLoads.map((operatorLoad) => ({
      name: unit.name,
      firstLoad: formatDate(operatorLoad.firstLoad),
      lastLoad: formatDate(operatorLoad.lastLoad, '00:00'),
      operatorName: operatorLoad.operatorName,
      loginTime: formatDate(operatorLoad.loginTime),
      logoutTime: formatDate(operatorLoad.logoutTime, '00:00'),
    })),
  );

export default function History() {
  const [isloading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    moment().format('yyyy-MM-DD'),
  );
  const [trucks, setTrucks] = useState([]);
  const [filteredData, setFilteredData] = useState({});
  const [data, setData] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [zonePDFData, setZonePDFData] = useState({});
  const [zonePDFJobs, setZonePDFJobs] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [dateChanged, setDateChanged] = useState(false);
  const fetchTrucks = useCallback(async () => {
    try {
      setIsLoading(true);
      let response = await CallWithAuth(
        'GET',
        `${GET_HISTORY}?date=${selectedDate}`,
      );
      if (response?.res?.data?.status === 200) {
        setTrucks(Object.keys(response?.res?.data?.data));

        setData(response?.res?.data?.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      throw error;
    }
  }, [selectedDate]);

  useEffect(() => {
    fetchTrucks();
  }, [fetchTrucks, selectedDate]);

  const onCreate = () => {
    const element = document.getElementById('print-this-div');

    // Save original styles
    const originalOverflow = element.style.overflowY;
    const originalMaxHeight = element.style.maxHeight;

    // Set to print size
    element.style.overflowY = 'visible';
    element.style.maxHeight = 'none';

    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      let imgHeight = (canvas.height * 210) / canvas.width;
      let heightLeft = imgHeight;
      let doc = new jsPDF('p', 'mm');
      let position = 0;

      doc.addImage(imgData, 'PNG', 0, position, 210, imgHeight);
      heightLeft -= 297;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, 210, imgHeight);
        heightLeft -= 297;
      }
      doc.save('document.pdf');

      // Restore original styles
      element.style.overflowY = originalOverflow;
      element.style.maxHeight = originalMaxHeight;
    });
  };

  const tableRows = useMemo(
    () => [
      {
        font: 'normal normal normal 12px/18px Open Sans',
        fontSize: '1rem',
        color: '#707070',
        key: 'loadtime',
        name: 'loadtime',
        child: (dataValue) => (
          <>{moment(dataValue?.loadtime).format('hh:mm a ')}</>
        ),
      },
      {
        font: 'normal normal normal 12px/18px Open Sans',
        fontSize: '1rem',
        color: '#1A1A1A',
        key: 'material',
        name: 'material',
        child: (dataValue) => <>{dataValue?.material}</>,
      },
      {
        font: 'normal normal normal 12px/18px Open Sans',
        fontSize: '1rem',
        color: '#1A1A1A',
        key: 'sourcepoint',
        name: 'sourcepoint',
        child: (dataValue) => <div>{dataValue?.sourcePoint}</div>,
      },
      {
        font: 'normal normal normal 12px/18px Open Sans',
        fontSize: '1rem',
        color: '#1A1A1A',
        key: 'escavator',
        name: 'escavator',
        child: (dataValue) => <div>{dataValue?.fromexc}</div>,
      },
      {
        font: 'normal normal normal 12px/18px Open Sans',
        fontSize: '1rem',
        color: '#1A1A1A',
        key: 'ex_operator',
        name: 'ex_operatort',
        child: (dataValue) => <div>{dataValue?.ex_operator}</div>,
      },
      {
        font: 'normal normal normal 12px/18px Open Sans',
        fontSize: '1rem',
        color: '#1A1A1A',
        key: 'excoperatory',
        name: 'excoperatory',
        child: (dataValue) => <div>{dataValue?.dumpPoint}</div>,
      },
      {
        font: 'normal normal normal 12px/18px Open Sans',
        fontSize: '1rem',
        color: '#1A1A1A',
        key: 'truckOperator',
        name: 'tr_operator',
        child: (dataValue) => <div>{dataValue?.tr_operator}</div>,
      },
      {
        font: 'normal normal normal 12px/18px Open Sans',
        fontSize: '1rem',
        color: '#707070',
        key: 'dumptime',
        name: 'dumptime',
        child: (dataValue) => (
          <div>{moment(dataValue?.dumpTime).format('HH:MM a')}</div>
        ),
      },
    ],
    [],
  );
  const { shift } = useContext(ShiftHistoryContext);

  const [downloadFields, setDownloadFields] = useState([
    { name: '', field: '', type: '', value: '', options: [] },
  ]);
  const [filteredShift, setFilteredShift] = useState(shift);
  const [showReport, setShowReport] = useState(false);

  // useEffect(() => {
  //   console.log(shift, selectedUnit);
  //   if (selectedUnit)
  //     setSelectedUnit(shift?.find((s) => s.id === selectedUnit?.id))
  // }, [shift])

  const [temporaryShift, setTemporaryShift] = useState([]);
  // useEffect(() => {
  //   if (temporaryShift.length === 0) return;

  //   generateShiftHistoryPDF('Shift-History', 'shift-history-table');
  //   setFilteredShift(temporaryShift);
  //   setTemporaryShift([]);
  // }, [temporaryShift]);

  const [selectedUnit, setSelectedUnit] = useState();

  const downloadSingleUnit = (unit) => {
    setTemporaryShift(filteredShift);
    setFilteredShift(
      unit.operatorLoads.map((operatorLoad) => ({
        ...unit,
        operatorLoads: [operatorLoad],
      })),
    );
  };

  function handleSearch(e) {
    setSearchQuery(e.target.value);
    if (e.target.value === '') {
      fetchTrucks();
      return;
    }
    const filterBySearch = trucks?.filter((item) => {
      if (item?.toLowerCase().includes(e.target.value?.toLowerCase())) {
        return item;
      }
    });

    setTrucks(filterBySearch);
  }

  const filterItem = (sourcekey, filterkey) => {
    let filteredArray = [];

    for (let i = 0; i < trucks?.length; i++) {
      const truck = trucks[i];
      console.log(data[truck], 'data[truck]');
      filteredArray = [
        ...filteredArray,
        ...(data[truck]?.filter(
          (e) => e?.[sourcekey] === filteredData?.[filterkey],
        ) || []),
      ];
    }
    const groupedData = filteredArray?.reduce((result, obj) => {
      const key = obj?.objectTitle;
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(obj);
      return result;
    }, {});

    setTrucks(Object.keys(groupedData));
    setData(groupedData);
  };
  const saveHandler = () => {
    // onSaveClick(copyFilteredData);
    if (filteredData?.materials) {
      filterItem('material', 'materials');
    }
    if (filteredData?.sourcepoint) {
      filterItem('sourcePoint', 'sourcepoint');
    }
    if (filteredData?.dumppoint) {
      filterItem('dumpPoint', 'dumppoint');
    }

    if (filteredData?.sourcepoint) {
      filterItem('sourcePoint', 'sourcepoint');
    }
    if (filteredData?.trucks) {
      filterItem('objectTitle', 'trucks');
    }

    if (filteredData?.excavators) {
      filterItem('fromexc', 'excavators');
    }

    if (filteredData?.operators) {
      filterItem('ex_operator', 'operators');
    }
  };

  return (
    <>
      <div
        className=" px-3 "
        style={{
          backgroundColor: 'white',
          width: '100%',
          height: '100%',
        }}
      >
        <div className=" d-flex justify-content-between mb-3">
          <div className=" mt-5">
            {' '}
            <p
              style={{
                font: 'normal normal 600 14px/19px Open Sans',
                fontSize: '1.15rem',
              }}
            >
              MATERIAL MOVEMENTS HISTORY
            </p>
          </div>
          <div>
            <SubHeader
              downloadFields={downloadFields}
              setDownloadFields={setDownloadFields}
              dateChanged={dateChanged}
              setDateChanged={setDateChanged}
              fetchTrucks={fetchTrucks}
              saveHandler={saveHandler}
              userFilter
              SubHeaderVisible={true}
              toShowFilter={true}
              showDownload
              onDownloadClick={() => setShowDownload(true)}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              handleSearch={handleSearch}
              searchQuery={searchQuery}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              // onSaveClick={() => setShowReport(true)}
              // onSaveClick={onFilterChange}
            />
          </div>
        </div>

        {isloading ? (
          <Loader />
        ) : (
          <main
            style={{
              minHeight: 'calc(100% - 180px)',

              width: '100%',
            }}
          >
            <div
              className="mm-table_manage_user flex-fill"
              id="user-list-table"
            >
              <table cellPadding="0" cellSpacing="0">
                <thead className="table-head">
                  <tr className="">
                    {headerText.map((header, index) => (
                      <th
                        style={{
                          width: index > 2 ? '5%' : '4.4%',
                          font: 'normal normal 600 12px/17px Open Sans',
                          color: 'black',
                          opacity: 1,
                          fontSize: '1rem',
                        }}
                        className="py-3"
                        align="left"
                        valign="top"
                        key={index}
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {trucks?.map((value, index) => (
                    <>
                      <tr className="table-head " key={index}>
                        <td>
                          <strong> {value}</strong>
                        </td>
                        {tableRows.map(({ key, child, style, fontSize }) => (
                          <td
                            style={{
                              width: index > 2 ? '1%' : '5%',
                              fontSize: fontSize,
                            }}
                            align="left"
                            valign="top"
                            key={key}
                          ></td>
                        ))}
                      </tr>
                      {data[value]?.map((userValue, userIndex) => (
                        <tr
                          className={`table-head ${
                            userValue?.dumpPoint === 'Unknown'
                              ? 'mm-shift_red_history'
                              : ''
                          } `}
                          key={userIndex}
                        >
                          <td align="left" valign="top"></td>
                          {tableRows?.map(
                            ({ key, child, font, color, fontSize }) => (
                              <td
                                style={{
                                  width: index > 1 ? '1%' : '1%',
                                  font: font,
                                  color: color,
                                  fontSize: '1rem',
                                }}
                                align="left"
                                valign="top"
                                key={key}
                              >
                                {child(userValue)}
                              </td>
                            ),
                          )}
                        </tr>
                      ))}
                    </>
                  ))}
                </tbody>
              </table>
            </div>

            {showReport && (
              <PdfModal
                onClose={() => {
                  setShowReport(false);
                  setZonePDFData({});
                  setZonePDFJobs([]);
                }}
                loading={downloading}
                onDownloadPress={onCreate}
              >
                <>
                  {/* {!isEmpty(zonePDFData) ? ( */}
                  <JobDailyReport
                    downloadFields={downloadFields}
                    setDownloadFields={setDownloadFields}
                    jobs={zonePDFJobs}
                    data={zonePDFData}
                    // filteredZones={filteredData.zones}
                  />
                  {/* ) : null} */}
                </>
              </PdfModal>
            )}
            {showDownload && (
              <ModalView
                onClose={() => setShowDownload(false)}
                backgroundColor={'white'}
                hide
              >
                <DownloadModal
                  selectedDate={selectedDate}
                  dateChanged={dateChanged}
                  downloadFields={downloadFields}
                  setDownloadFields={setDownloadFields}
                  // data={filteredData}
                  onCancel={() => setShowDownload(false)}
                  onCreate={() => {
                    setShowReport(true);
                    setShowDownload(false);
                  }}
                  // downloading={downloading}
                  type="job"
                />
              </ModalView>
            )}
          </main>
        )}
      </div>
    </>
  );
}
