import { useContext, useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { CgClose, CgSearch } from 'react-icons/cg';
import { GET_VEHICLE_EVENTS } from '../action/apiPath';
import { DumpPointsContext } from '../DumpPointsContext';
import DumpPointDetailModal from './DumpPointsDetailModal';
import { Tooltip } from 'react-tooltip';
import Loader from '../components/Loader';
import truck from '../assets/images/trucks.svg';
import { CallWithAuth } from '../action/apiActions';

export default function TruckLoadsModal(props) {
  const {
    selectedtruck,
    setSelectedTruck,
    materials = [],
    fitScreen = () => {},
  } = props;
  const { setIsDetailPageOpen } = useContext(DumpPointsContext);

  const [searchQuery, setSearchQuery] = useState();

  const fetchDumpPoints = async () => {
    try {
      const response = await CallWithAuth('GET', `${GET_VEHICLE_EVENTS}`);
      if (response?.res?.data?.status === 200) {
        setData(response?.res?.data?.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchDumpPoints();
  }, []);

  function getMaterialColor(materialType) {
    console.log('_________________________', materialType);
    const material = materials.find(
      (item) => item?.MaterialType === materialType,
    );

    return material ? material.color : '#000000'; // Replace "#000000" with your desired default color
  }

  function getMaterial(truckName, truckData) {
    const truck = truckData.find((truck) => truck.name === truckName);

    if (!truck) {
      return []; // Return an empty array if the truck is not found
    }

    const materialsSet = new Set(); // Use a Set to collect distinct materials
    truck.allLoads.forEach((load) => {
      load.loads.forEach((item) => {
        materialsSet.add(item.material);
      });
    });

    const materials = Array.from(materialsSet);
    return materials;
  }

  function handleSearch(e) {
    setSearchQuery(e.target.value);

    console.log(data, 'dskdjs');
    if (e.target.value === '') {
      fetchDumpPoints();
      return;
    }
    const filterBySearch = data?.filter((item) => {
      if (item?.name?.toLowerCase().includes(e.target.value?.toLowerCase())) {
        return item;
      }
    });
    setData(filterBySearch);
  }

  const [showDetailModal, setShowDetailModal] = useState(false);
  const [data, setData] = useState([]);

  if (showDetailModal) {
    setIsDetailPageOpen(true);
    return <DumpPointDetailModal close={() => setShowDetailModal(false)} />;
  } else {
    setIsDetailPageOpen(false);
  }

  const removeDuplicatesById = (array) => {
    const uniqueIds = [];
    return array.filter((item) => {
      if (!uniqueIds.includes(item._id)) {
        uniqueIds.push(item._id);
        return true;
      }
      return false;
    });
  };

  const ActiveTable = () => {
    data?.sort((a, b) => a?.name?.localeCompare(b?.name));
    return (
      <div
        style={{
          height: 'calc(100% - 4.2rem)',
          borderRadius: 5,
          width: '100%',
        }}
      >
        <tr
          className="d-flex"
          style={{
            font: 'normal normal 600 13px/17px Open Sans',
            fontSize: '1.08rem',
            padding: '1.15rem',
            borderBottom: '1px solid black',
            borderTop: '1px solid black',
          }}
        >
          <td
            style={{
              width: '3%',
            }}
          />
          <td className=" mm-global-font-1" style={{ width: '36%' }}>
            {'Trucks'}
          </td>
          <td className=" mm-global-font-1" style={{ width: '36%' }}>
            {'Materials'}
          </td>
          <td className=" mm-global-font-1" style={{ width: '22%' }}>
            Total Loads
          </td>
          <td className=" mm-global-font-1" style={{ width: '22%' }}>
            Unknown
          </td>
        </tr>
        <div
          style={{
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100% - 3.33rem)',
            padding: '0 0px 0',
          }}
        >
          <div
            style={{
              paddingBottom: '4.2rem',
              height: 'max-content',
            }}
            className="d-flex flex-column"
          >
            {removeDuplicatesById(data)?.map((source, index) => {
              return (
                <tr
                  onClick={() => {
                    setSelectedTruck({});
                    setSelectedTruck({
                      ...source,
                      showpopup: true,
                      visible: true,
                      pointVisible: true,
                      fitScreen: true,
                    });
                  }}
                  className={`d-flex  align-items-stretch ${
                    source?.totalUnknowns > 0 ? 'mm-shift_red' : ''
                  }`}
                  key={index}
                  style={{
                    background:
                      selectedtruck?._id === source._id
                        ? '#05795F1A 0% 0% no-repeat padding-box'
                        : '#FFFFFF66 0% 0% no-repeat padding-box',

                    fontSize: '1.08rem',
                    width: '100%',
                    borderTop:
                      selectedtruck?._id === source._id
                        ? '1px solid #198f23'
                        : '',
                    borderBottom:
                      selectedtruck?._id === source._id
                        ? '1px solid #198f23'
                        : '1px solid #EBEBEB',

                    padding: '1.65rem 1.25rem',
                  }}
                >
                  <td
                    style={{
                      width: source?.totalUnknowns > 0 ? '1%' : '3%',
                      borderTopLeftRadius: 5,
                      borderBottomLeftRadius: 5,
                    }}
                  />

                  <td
                    className="py-2 mm-global-font-2"
                    style={{ width: '36%', lineHeight: '10%' }}
                  >
                    {source?.name ?? '-'}
                  </td>

                  <td
                    className="py-2 mm-global-font-2"
                    style={{ width: '36%', lineHeight: '10%' }}
                  >
                    {source?.totalLoads && source?.totalLoads !== 0 ? (
                      getMaterial(source?.name, data).length > 1 ? (
                        <a
                          href="#"
                          data-tooltip-id="mm-my-tooltip-data-html"
                          data-tooltip-html={`<ul style="width:max-content">
                                              ${getMaterial(source?.name, data)
                                                ?.map(
                                                  (
                                                    e,
                                                    i,
                                                  ) => `<li  style="font-size:1.1rem"  key=${i}>
                                              <span class=${
                                                e === 'Coal'
                                                  ? 'mm-dot-1'
                                                  : 'mm-dot-2'
                                              } style="background-color:${getMaterialColor(
                                                e,
                                              )}" ></span>
                                            ${e}</li>`,
                                                )
                                                ?.join('')}
                                        </ul>`}
                        >
                          {getMaterial(source?.name, data)?.length ?? '-'}
                        </a>
                      ) : (
                        <div>
                          {getMaterial(source?.name, data)?.map((e) => e)}
                        </div>
                      )
                    ) : (
                      '-'
                    )}
                  </td>
                  <td
                    className="py-2 mm-global-font-2"
                    style={{ width: '22%', lineHeight: '10%' }}
                  >
                    {source?.totalLoads ?? '-'}
                  </td>
                  <td
                    className="py-2 mm-global-font-2"
                    style={{ width: '22%', lineHeight: '10%' }}
                  >
                    {source?.totalUnknowns ?? '-'}
                  </td>
                </tr>
              );
            })}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div
      className="mx-3 px-1"
      style={{
        top: '4.7rem',
        left: '0',
        position: 'absolute',
        width: '600px',
        height: 'calc(100% - 11.8rem)',
        background: ' #FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: ' 0px 3px 6px #00000029',
        borderRadius: '0.85rem',
        zIndex: 2,
      }}
    >
      <div
        style={{
          padding: '1.25rem 2.33rem',
          borderRadius: '0.85rem 0.85rem 0px 0px',
        }}
      >
        <div
          className="d-flex justify-content-between  align-items-center"
          style={{ marginBottom: '2rem' }}
        >
          <div className="d-flex align-items-center">
            <Image src={truck} style={{ height: '1.7rem' }} />
            <span
              style={{
                font: 'normal normal 600 18px/24px Open Sans',
                fontSize: '1.5rem',
                marginLeft: '5px',
              }}
            >
              TRUCKS
            </span>
          </div>
          <CgClose
            onClick={props.close}
            style={{ cursor: 'pointer' }}
            size={27}
            className="px-1"
          />
        </div>
        <div
          className="px-2 my-2 d-flex align-items-center "
          style={{
            height: '3rem',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            border: '1px solid #EBEBEB',
            display: 'flex',
            alignItems: 'center',
            borderRadius: '3.33rem',
            width: '100%',
            padding: 8,
          }}
        >
          <CgSearch color="gray" size={25} style={{ margin: '0 7px' }} />
          <input
            type="text"
            placeholder={'Search for a truck'}
            className="shadow-none"
            style={{
              paddingBottom: '0px',
              paddingTop: '0px',
              borderTop: '1px solid #EBEBEB',
              borderBottom: '1px solid #EBEBEB',
              border: 'none',
              font: 'normal normal normal 14px/19px Open Sans',
              fontSize: '1.15rem',
            }}
            value={searchQuery}
            onChange={(e) => handleSearch(e)}
          />
        </div>
      </div>

      <div
        style={{
          padding: '1.25rem 2.33rem',
          height: 'calc(100% - 11rem)',
        }}
      >
        {data?.length === 0 ? <Loader /> : <ActiveTable />}

        {
          <p
            //   onClick={() => onSelect({})}
            className=" d-flex justify-content-end mt-5"
            style={{
              color: '#387FF3',
              cursor: 'pointer',
              fontSize: '1rem',
            }}
            onClick={() =>
              setSelectedTruck({
                // ...selectedtruck,
                visible: false,
                pointVisible: false,
                fitScreen: true,
              })
            }
          >
            {selectedtruck?._id ? 'Show All Loads' : ''}{' '}
          </p>
        }
      </div>

      <Tooltip
        id="mm-my-tooltip-data-html"
        place={'bottom'}
        style={{
          width: '11.5rem',
          boxShadow: '1px 6px 10px #00000029',
          backgroundColor: '#FFFFFF',
          color: 'black',
          border: '1px solid #EBEBEB',
          borderRadius: '0.33rem',
        }}
      />
    </div>
  );
}
